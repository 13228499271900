.admin_layout_content {
  max-height: calc(100vh - 70px);
  overflow: auto;
  padding: 20px;
}

.admin_layout .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/images/logo-cardio4all-white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 20px;
  height: 45px;
}

.admin_layout .ant-layout-header {
  background-color: #001529;
}

.ant-layout-sider-collapsed .logo {
  background-image: url("../../assets/images/logo-cardio4all-white.svg");
}

.languages_dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  line-height: 1;
  margin-left: 20px;
}

.languages_dashboard .lang-item {
  padding: 5px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  margin: 6px;
  text-transform: uppercase;
  cursor: pointer;
}

.languages_dashboard .lang-item.selected {
  padding: 5px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  background: #fff;
  color: #061848;
}
