.login_content {
  background-position: bottom right;
  background-size: cover;
}

@media screen and (max-width: 600px) {
  .login_content {
    background-position: bottom left !important;
    background-size: cover;
  }
}

.login-form input,
.login-form .ant-input-password {
  border-radius: 23px !important;
  background-color: #fff !important;
  color: #0e426a;
}

.login-form input::placeholder,
.login-form .ant-input-password {
  color: rgba(28, 28, 28, 0.5);
}

.login-form .ant-input-password .ant-input-password-icon {
  color: #979797 !important;
}

.login-form .login-btn {
  background-color: #f04c4b;
  border: 1px solid #f04c4b;
  font-size: 18px;
  font-weight: 500;
  height: auto !important;
  padding: 8px 25px;
  border-radius: 0 !important;
}

.login-form .login-btn:hover {
  background-color: transparent !important;
  color: #f04c4b !important;
  border: 1px solid #f04c4b;
  font-size: 18px;
  font-weight: 500;
  height: auto !important;
  padding: 8px 25px;
  border-radius: 0 !important;
}

.login-form .register-btn {
  background-color: transparent;
  border: 1px solid #fff;
  font-size: 18px;
  height: auto !important;
  padding: 8px 25px;
  border-radius: 0 !important;
}

.login-form .register-btn:hover {
  background-color: #cccccc !important;
  border: 1px solid #cccccc;
  font-size: 18px;
  height: auto !important;
  padding: 8px 25px;
  border-radius: 0 !important;
}
